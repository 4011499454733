.plans_heading{
    text-align: center;
    font-size: 50px;
    color: #000;
    margin:20px 0px;
}

.plans_heading_div hr{
    background-color: #000;
    height: 4px;
    margin-bottom: 20px;
    margin-top: -20px;
    width: 400px;
}

.plans_row{
    display: grid;
    grid-template-columns: auto auto auto;
    padding-top: 20px;
    padding-bottom: 30px;
    justify-content: space-around;
    padding: 0px 150px;

}

.plans_col_3{
    height: 430px;
    width: 300px;
    border: 1px solid #000;
    border-radius: 10px;
    margin-top: 25%;
    position: relative;
    margin-bottom: 10px;
}

.price li{
    float: left;
    text-align: center;
    padding-left: 10px;
}

.plans_col_3 button{
    border: none;
    background-color: #000;
    color: #fff;
    padding: 8px;
    border-radius:10px;
    /* margin-top: 10px; */
    position: absolute;
    bottom: -4%;
    right: 28%;
}

.plans_col_3 button:hover{
    scale: 1.1;
    transition: 0.4s;
}

.plans_card_heading{
    padding: 20px 0px;
    color: #000;
    border-bottom: 1px solid #fff;
    width: 250px;
 }

 .price p{
    color: #000;
    font-size: 13px;
    padding: 4px 0px;
 }

 .price p strong{
    font-size: 14px;
 }

.price_section_container{
    position: relative;
}

 .price_side_img{
    position: absolute;
    left: -13%;
    top: -30%;
    height:400px;
 }

.animation-container {
    width: 60px;
    height: 60px;
    perspective: 600px;
    margin: 20px auto;
}

.cube1 {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    animation: rotate 10s infinite linear;
}

.cube1-face {
    position: absolute;
    width: 50px;
    height: 50px;
    /* background:#554140; */
     border: 2px solid #554140;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #000;
}

.cube1-face:nth-child(1) { transform: rotateY(0deg) translateZ(30px); }
.cube1-face:nth-child(2) { transform: rotateY(90deg) translateZ(30px); }
.cube1-face:nth-child(3) { transform: rotateY(180deg) translateZ(30px); }
.cube1-face:nth-child(4) { transform: rotateY(-90deg) translateZ(30px); }
.cube1-face:nth-child(5) { transform: rotateX(-90deg) translateZ(30px); }
.cube1-face:nth-child(6) { transform: rotateX(90deg) translateZ(30px); }

@keyframes rotate {
    0% { transform: rotateY(0deg) rotateX(0deg); }
    100% { transform: rotateY(360deg) rotateX(360deg); }
}    


/* Responsive Adjustments */
@media screen and (max-width: 1200px) {
    
    .plans_row{
        display: grid;
        grid-template-columns: auto auto auto;
        padding-top: 20px;
        padding-bottom: 30px;
        justify-content: space-around;
        padding: 0px 0px;
    
    }
    
    .plans_col_3{
        height: 425px;
        width: 265px;
        border: 1px solid #000;
        border-radius: 10px;
        margin-top: 30%;
        position: relative;
        margin-bottom: 10px;
    }
    
    .price li{
        float: left;
        text-align: center;
        padding-left: 10px;
    }
    
    .plans_col_3 button{
        border: none;
        background-color: #000;
        color: #fff;
        padding: 8px;
        border-radius:10px;
        margin-top: 10px;
        position: absolute;
        bottom: -4%;
        right: 28%;
    }
    
    .plans_card_heading{
        padding: 20px 0px;
        color: #000;
        border-bottom: 1px solid #fff;
        width: 250px;
     }
    
     .price p{
        color: #000;
        font-size: 13px;
        padding: 4px 0px;
     }
    
     .price p strong{
        font-size: 14px;
     }
    
    .price_section_container{
        position: relative;
    }
    
     .price_side_img{
        position: absolute;
        left: -13%;
        top: -35%;
        height:300px;
        /* z-index: 10; */
     }
}

@media screen and (max-width: 768px) {
    .plans_heading{
        text-align: center;
        font-size: 25px;
        color: #000;
        margin:20px 0px;
    }
    
    .plans_heading_div hr{
        background-color: #000;
        height: 4px;
        margin-bottom: 20px;
        margin-top: -20px;
        width: 200px;
    }
    
    .plans_row{
        display: grid;
        grid-template-columns: auto;
        padding-top: 20px;
        padding-bottom: 30px;
        justify-content: space-around;
        padding: 0px 0px;
    
    }
    
    .plans_col_3{
        height: 430px;
        width: 300px;
        border: 1px solid #000;
        border-radius: 10px;
        margin-top: 25%;
        position: relative;
        margin-bottom: 10px;
    }
    
    .price li{
        float: left;
        text-align: center;
        padding-left: 10px;
    }
    
    .plans_col_3 button{
        border: none;
        background-color: #000;
        color: #fff;
        padding: 8px;
        border-radius:10px;
        margin-top: 10px;
        position: absolute;
        bottom: -4%;
        right: 28%;
    }
    
    .plans_card_heading{
        padding: 20px 0px;
        color: #000;
        border-bottom: 1px solid #fff;
        width: 250px;
     }
    
     .price p{
        color: #000;
        font-size: 13px;
        padding: 4px 0px;
     }
    
     .price p strong{
        font-size: 14px;
     }
    
    .price_section_container{
        position: relative;
    }
    
     .price_side_img{
        position: absolute;
        left: -25%;
        top: -7%;
        height:200px;
        /* z-index: 10; */
     }
}