.header {
    text-align: center;
    padding: 2rem 0;
}

.hr_line{
    border: 1px solid #d2d2d2eb
}

.page-title {
    margin: 1rem 0;
    color: #333;
    text-align: center;
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px,1fr));
    gap: 2rem;
    margin: 1rem;
}

.service-card {
    background: #f5f0ec;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
}

.service-card:hover {
    transform: translateY(-5px);
}

.service-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 1rem;
}

.service-title {
    color: #333;
    margin-bottom: 1rem;
    font-size: 1.2rem;
}

.service-description {
    color: #666;
    line-height: 1.6;
    font-size: 0.9rem;
}

.faq-section {
    margin-top: 3rem;
}

.faq-section h2{
 text-align: center; 
 margin-bottom: 20px;
}

.accordion {
    max-width: 800px;
    margin: 0 auto;
}

.accordion-item {
    background: #f5f0ec;
    margin-bottom: 1rem;
    border-radius: 8px;
    overflow: hidden;
}

.accordion-header {
    padding: 1rem;
    background: #4a3f3f;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.accordion-content {
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
    background: #f5f0ec;
    /* transition: padding 1s max-height 0.5s; */
}

.accordion-content-inner {
    padding: 1rem;
    color: #666;
}

.accordion-item.active .accordion-content {
    max-height: 500px;
}

.accordion-header::after {
    content: '+';
    font-size: 1.5rem;
}

.accordion-item.active .accordion-header::after {
    content: '-';
}

.home-link {
    text-decoration: none;
    color: #333;
    float: right;
    margin: 1rem;
}

@media screen and (max-width: 768px) {
    .page-title {
        margin:1rem 0;
        color: #333;
        text-align: center;
        font-size: 20px;
    }

    .services-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px,1fr));
        gap: 2rem;
        margin:1rem;
    }
}