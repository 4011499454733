
.inner_container_term_condition{
    position: sticky;
    position: -webkit-sticky;
    top: -1px;
    display: flex;
    width: 100vw;
}

.inner_container_term_condition a{
    font-size: 20px;
    text-decoration: none;
    color: #000;
    /* border: 1px solid #000; */
    padding: 10px 10px 0px 10px;
    text-align: center;
    border-radius: 5px;
    box-shadow: 2px 3px 5px #00000057;
    background-color: #E3DAD2;
}

.inner_container_term_condition h4{
   color: #000;
   height: 50px;
   width: 100vw;
   padding-top: 5px;
 
}


.second_inner_container{
    text-align: center;
   color: #000;
   width: 100vw;
}


.text_container{
    color: #000;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
}

.text{
   width:auto;
   letter-spacing: 1px;
   color: #000;
   padding-bottom: 50px;
}

.logo_t_c{
    color: #000;
    letter-spacing: 10px;
    font-size: 40px;
    text-align: center;
}

.logo_t_c img{
    height: 200px;
    width: 400px;
}

@media screen and (max-width: 768px) {
    .logo_t_c img{
        height: 100px;
        width: 250px;
    }
    
}