@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');
*{
  margin: 0;
    padding: 0;
    box-sizing: border-box;
}
#root{
  height: 100vh;
  width: 100vw;
  background-color: #E3DAD2;
  font-family: "JetBrains Mono" , monospace;
  /* font-family: ; */
  overflow-x: hidden;
  scroll-behavior: smooth;
}

::-webkit-scrollbar{
  /* width: 14px; */
  display: none;
}

::-webkit-scrollbar-track{
  background-color: #fff;
    /* border-radius: 10px; */
}

::-webkit-scrollbar-thumb{
  background-color: #84848467;
  /* border-radius: 10px; */
}

/* @keyframes fadeInAnimation {
  from{
    opacity: 0;
    scale: 0.5;
  }
  to{
    opacity: 1;
    scale: 1;
  }
}

body {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  /* animation-timeline: view();
  animation-range: entry 0% cover 50%;} */



  @keyframes appear {
    from{
      opacity: 0.9;
      scale: 0.8;
    }
    to{
      opacity: 1;
      scale: 1;
    }
  }
  
  .block{
    animation: appear 3s linear;
    animation-timeline: view();
    animation-range: entry 0% cover 40%;
  }
  
