.aboutus_container{
    width: auto;
}

.top_div{
    display: flex;
    justify-content: space-between;
    line-height: 60px;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background:#70707030;;
    opacity: 0.8;

  }
  
  .main_title{
      color: #000;
      text-align: center;
      line-height: 60px;
      margin-top: 15px;   
  }
  
  
  .top_div a{
      font-size: 20px;
      /* text-decoration: none; */
      color: #000;
      /* border: 1px solid #000; */
      padding: 10px 20px 0px 20px;
      text-align: center;
      border-radius: 20px;
      /* background-color: #E3DAD2; */
  }
  
  .logo{
      width: 120px;
      height: 100px;
  }

  .text_container{
    margin-top: 5%;
    width: auto;
    text-align: center;
  }

  @media only screen and (max-width: 900px) {
    .top_div{
        display: flex;
        justify-content: space-between;
        line-height: 60px;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        /* background-color: black; */

        opacity: 0.8;
        font-size: 10px;
        /* text-align: center; */
      }
      
      .main_title{
          color: #000;
          text-align: center;
          font-size: 14px;
          line-height: 60px;
          margin-top: 0px;
          margin-left: 10%;
      }

      .top_div a{
        padding: 0px 20px 0px 20px;
    }
      
      .logo{
          width: 75px;
          height: 60px;
      }

      .text_container{
        text-align: center;
        width: auto;
      }

  }