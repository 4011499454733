.middle_container{
    display: grid;
    grid-template-columns: auto auto auto;
    padding-top: 5%;
}


.card1 {
    width: 250px;
    height: 254px;
    border-radius: 10px;
    background: #e0e0e0;
    box-shadow: 5px 5px 40px #00000049;
    margin-bottom: 30px;
   }

.card1 img{
    width: 250px;
    height: 254px;
    border-radius: 10px;
    object-fit: cover;
}

button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
    margin-bottom: 20px;
   }
   
   button.get-started {
    width: 12rem;
    height: auto;
   }
   
   button.get-started .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background: #282936;
    border-radius: 1.625rem;
   }
   
   button.get-started .circle .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
   }
   
   button.get-started .circle .icon.arrow {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    left: 0.625rem;
    width: 1.125rem;
    height: 0.125rem;
    background: none;
   }
   
   button.get-started .circle .icon.arrow::before {
    position: absolute;
    content: "";
    top: -0.29rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid #fff;
    border-right: 0.125rem solid #fff;
    transform: rotate(45deg);
   }
   
   button.get-started .button-text {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0;
    margin: 0 0 0 1.85rem;
    color: #282936;
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
    text-transform: uppercase;
   }
   
   button:hover .circle {
    width: 100%;
   }
   
   button:hover .circle .icon.arrow {
    background: #fff;
    transform: translate(1rem, 0);
   }
   
   button:hover .button-text {
    color: #fff;
   }

@media screen and (max-width: 768px) {
    .middle_container{
        display: grid;
        grid-template-columns: auto;
        padding-top: 5%;
    }
    
    
    .card1 {
        width: 200px;
        height: 200px;
        border-radius: 10px;
        background: #e0e0e0;
        box-shadow: 5px 5px 40px #00000049;
        margin-bottom: 30px;
       }
    
    .card1 img{
        width: 200px;
        height: 200px;
        border-radius: 10px;
        object-fit: cover;
    }
}