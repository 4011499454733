.top_div {
    display: flex;
    justify-content: space-between;
    line-height: 60px;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    /* background-color: black; */

    opacity: 0.8;
    font-size: 10px;
    z-index: 999;
}

.warning {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 500px;
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    background: #F7C752;
    border-radius: 8px;
    box-shadow: 0px 0px 5px -3px #111;
}

.warning__icon {
    width: 20px;
    height: 20px;
    transform: translateY(-2px);
    margin-right: 8px;
}

.warning__icon path {
    fill: #393A37;
}

.warning__title {
    font-weight: 500;
    font-size: 14px;
    color: #393A37;
}

.warning__close {
    width: 20px;
    height: 20px;
    margin-left: auto;
    cursor: pointer;
}

.warning__close path {
    fill: #393A37;
}


.card {
    position: relative;
    width: 650px;
    height: 420px;
    background-color: #E3DAD2;
    border-radius: 10px;
    border: 1px solid #00000078;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    perspective: 1000px;
    /* box-shadow: 0 0 0 5px #131313; */
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}



.card img {
    width: 650px;
    height: 420px;
    fill: #333;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);
}

.card__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #000000ae;
    backdrop-filter: blur(10px);
    transform: rotateX(-90deg);
    transform-origin: bottom;
    transition: ease 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.card:hover .card__content {
    transform: rotateX(0deg);
}

.card__title {
    margin: 0;
    font-size: 24px;
    color: #ffffff;
    font-weight: 700;
}

.card:hover svg {
    scale: 0;
}

.card__description {
    margin: 10px 0 0;
    font-size: 14px;
    color: #bfbfbf;
    line-height: 1.4;
}

.project_div {
    display: grid;
    grid-template-columns: auto auto;
    margin: 60px 0px;
    /* margin-left: 20px; */
}


.view_more_btn {
    border: 1px solid #090909;
    color: #000;
}

.futuristic-btn {
    position: relative;
    padding: 8px 40px;
    margin-top: 10px;
    font-size: 18px;
    color: #000000;
    background-color: transparent;
    border: 1px solid #00000058;
    border-radius: 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.3s ease;
    outline: none;
}

.btn-text {
    position: relative;
    z-index: 2;
}

.btn-glow {
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(222, 222, 222, 0.3) 0%, rgba(232, 232, 232, 0) 70%);
    transform: scale(0);
    transition: transform 0.5s ease;
}

.futuristic-btn:hover {
    color: #ffffff;
    text-shadow: 0 0 5px rgba(28, 28, 28, 0.7);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    border: none;

}

.futuristic-btn:hover .btn-glow {
    transform: scale(1);
}

.futuristic-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #7c7070;
    transition: all 0.4s ease;
    z-index: 1;
}

.futuristic-btn:hover::before {
    left: 0;
}

.video-card {
    background: linear-gradient(145deg, #f5eee8, #d1c8c0);
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.444);
    overflow: hidden;
    max-width: 600px;
    width: 90%;
    transition: transform 0.3s ease;
}

.video-card:hover {
    transform: scale(1.02);
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
}

.video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card-content {
    padding: 20px;
    color: #000000;
}

.card_title {
    margin-top: 0;
    font-size: 24px;
    background: linear-gradient(90deg, #B8A99A, #8C7A6B);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

p {
    font-size: 16px;
    line-height: 1.5;
}

.main_video_con {
    display: grid;
    grid-template-columns: auto auto;
    margin: 30px 0px 30px 50px;
    grid-row-gap: 30px;
}

.btn-text a{
    text-decoration: none;
    color: #000;
}

@media only screen and (max-width: 1320px) {

    .card {
        width: 600px;
        height: 380px;
    }



    .card img {
        width: 600px;
        height: 380px;
    }

    .project_div {
        display: grid;
        grid-template-columns: auto auto;
        margin: 30px 0px;
        margin-left: 20px;
    }

}

@media only screen and (max-width: 1205px) {

    .card {
        width: 480px;
        height: 380px;
    }



    .card img {
        width: 480px;
        height: 380px;

    }

    .project_div {
        display: grid;
        grid-template-columns: auto auto;
        margin: 30px 0px;
        /* margin-left: 20px; */
    }




}


@media only screen and (max-width: 1000px) {
    .card {
        width: 600px;
        height: 500px;
    }

    .card img {
        width: 600px;
        height: 500px;
    }

    .project_div {
        display: grid;
        grid-template-columns: auto;
        margin: 30px 0px;
        /* margin-left: 20px; */
    }

    .main_video_con {
        display: grid;
        grid-template-columns: auto;
        margin: 30px 0px 30px 140px;
    }


}

@media only screen and (max-width: 610px) {
    .card {
        width: 350px;
        height: 350px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .card img {
        width: 350px;
        height: 350px;

    }

    .project_div {
        display: grid;
        grid-template-columns: auto;
        margin: 30px 0px;
        /* margin-left: 20px; */
    }

    .warning {
        width: 300px;
    }

    .main_video_con {
        display: grid;
        grid-template-columns: auto;
        margin: 30px 0px 30px 20px;
    }

    .video-card {
        width: 95%;
    }

    h2 {
        font-size: 20px;
    }

    p {
        font-size: 14px;
    }
}

@media only screen and (max-width: 350px) {
    .card {
        width: 330px;
        height: 350px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .card img {
        width: 330px;
        height: 350px;
    }
}