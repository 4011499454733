.plans_heading_new{
    text-align: center;
    font-size: 50px;
    color: #000;
    margin:40px 0px;
}

.plans_heading_div_new hr{
    background-color: #000;
    height: 4px;
    margin-bottom: 20px;
    margin-top: -20px;
    width: 400px;
}

.pricing-container {
    display: flex;
    gap: 20px;
    max-width: 1200px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}

.pricing-card {
    background: white;
    border-radius: 12px;
    padding: 24px;
    flex: 1;
    min-width: 280px;
    max-width: 360px;
}

.pricing-card.featured span {
    font-size: 10px;
    /* font-weight: bold; */
}

.pricing-card.featured span strong{
    font-size: 12px;
}
.card-title {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-bottom: 8px;
}

.card-description {
    color: #666;
    font-size: 14px;
    margin-bottom: 24px;
    line-height: 1.4;
}

.price {
    font-size: 32px;
    font-weight: 700;
    color: #333;
    margin-bottom: 4px;
}

.billing-period {
    color: #666;
    font-size: 14px;
    margin-bottom: 24px;
}

.cta-button {
    background: #5b5fef;
    color: white;
    border: none;
    padding: 12px;
    border-radius: 6px;
    width: 100%;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 24px;
    transition: background-color 0.3s ease;
}

.cta-button:hover {
    background: #4a4ed0;
}

.features-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
    color: #333;
}

.features-list {
    list-style: none;
}

.feature-item {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
    color: #444;
    font-size: 14px;
}

.feature-item::before {
    content: "✓";
    color: #5b5fef;
    font-weight: bold;
}

@media (max-width: 768px) {
    .pricing-container {
        flex-direction: column;
        align-items: center;
    }

    .pricing-card {
        width: 100%;
    }
}

/* Responsive Adjustments */


@media screen and (max-width: 768px) {
    .plans_heading_new{
        text-align: center;
        font-size: 25px;
        color: #000;
        margin:20px 0px;
    }
    
    .plans_heading_div_new hr{
        background-color: #000;
        height: 4px;
        margin-bottom: 20px;
        margin-top: -20px;
        width: 200px;
    }
}