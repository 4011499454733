body{
    font-family: 'roboto',sans-serif;
}

.aco_Div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.top_div{
  display: flex;
  justify-content: space-between;
  line-height: 60px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background:#70707030;;
  opacity: 0.8;
}

.main_title{
    color: #000;
    text-align: center;
    line-height: 60px;
    margin-top: 15px;   
}


.top_div a{
    font-size: 20px;
    /* text-decoration: none; */
    color: #000;
    /* border: 1px solid #000; */
    padding: 10px 20px 0px 20px;
    text-align: center;
    border-radius: 20px;
    /* background-color: #E3DAD2; */
}

.logo{
    width: 150px;
    height: 90px;
}


.title_div{
    text-align: center;
    color: #000;
}

.title_div h1{
    color: #000;
}
.q_a_section{
    width: 650px;
}

.question{
    margin-top: 40px;
    margin-bottom: 5px;
    font-weight: 500px;
    color: #000;
}

.answer{
    margin-top: 10px;
    letter-spacing: 2px;
}

.sign{
    /* float: left; */
    color: #000;
}

.logo_footer{
   height: 200px;
}

hr{
  background-color: #000;
}

.row{
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 45px;
    width: auto;
    margin: 20px;
    color: #000;
}

.col-6{
    border: 1px solid #000;
    padding: 10px;
    border-radius:10px;
}

.col-6:hover{
    transition: 1s;
    box-shadow: 0px 4px 20px #00000091;
}

.svg svg{
    height: 50px;
    width: 100px;
}



@media only screen and (max-width: 900px) {
    .top_div{
        display: flex;
        justify-content: space-between;
        line-height: 60px;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        /* background-color: black; */

        opacity: 0.8;
        font-size: 10px;
      }
      
      .main_title{
          color: #000;
          text-align: center;
          font-size: 15px;
          line-height: 60px;
          margin-top: 0px;
          margin-left: 10%;
      }

      .top_div a{
        padding: 0px 20px 0px 20px;
    }
      
      .logo{
          width: 79px;
          height: 60px;
      }

      .logo_footer{
        width: 400px;
        height:200px;
      }

      .title_div h1{
        font-size: 20px;
        color: #000;
        margin-top: 30px;
    }
      
    .q_a_section{
        width: 344px;
    }

    .question{
        margin-top: 30px;
        margin-bottom: 5px;
        font-weight: 500px;
        color: #000;
    }

    .answer{
        margin-top: 10px;
    }
   
    .sign{
        float: left;
        color: #000;
    }

    .row{
        display: grid;
        grid-template-columns: auto;
        color: #000;
    }
    
    .col-6{
        border: 1px solid #000;
        padding: 10px;
    }
    
    
    .svg svg{
        height: 50px;
        width: 100px;
    }
}


@media only screen and (max-width: 400px) {
    .logo_footer{
        width: 300px;
        height:200px;
      }

}