.tech_heading {
    color:#000;
    font-size: 20px;
    text-align: center;
    margin-top:5%;
}

.hr_heading{
    border-bottom: 1px solid #000000; 
    width: 400px; 
}

.tech_icon_row{
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 20px;
    margin-top: 20px;
    margin-left: 13%;
    background: transparent;
}

.icon_col svg{
    color: #000000;
}


@media only screen and (max-width: 900px) {

    .tech_heading{
        color:#000;
        font-size: 10px;
        text-align: center;
        margin-top: 30px;
    }

    .tech_icon_row{
        grid-template-columns: auto auto auto ;
        margin-top: 40px;
        margin-left: 13%;
    }
    
}

@media only screen and (max-width: 600px) {
    .tech_icon_row{
        grid-template-columns: auto auto ;
        margin-top: 60px;
        margin-left: 15%;
    }

    .hr_heading{
        margin-top:5px;
        width: 200px; 
    }
}