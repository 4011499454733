*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  height: 100vh;
  width: 100vw;
  background-color: #E3DAD2;
}

